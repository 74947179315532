import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-732284e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  key: 0,
  class: "fs-6 badge badge-danger d-block"
}
const _hoisted_7 = {
  key: 1,
  class: "fs-6 badge badge-success d-block"
}
const _hoisted_8 = {
  key: 2,
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_review",
  class: "btn btn-light-primary mx-5"
}
const _hoisted_9 = { class: "card-body pt-3" }
const _hoisted_10 = { class: "mb-10" }
const _hoisted_11 = { class: "d-flex flex-wrap py-5" }
const _hoisted_12 = { class: "flex-equal me-5" }
const _hoisted_13 = {
  class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0",
  style: {"table-layout":"fixed","width":"100%"}
}
const _hoisted_14 = { class: "w-200px" }
const _hoisted_15 = { class: "text-gray-400 min-w-175px w-200px" }
const _hoisted_16 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_17 = { class: "text-gray-800 min-w-200px text-hover-gray" }
const _hoisted_18 = { class: "text-gray-400 min-w-175px w-200px" }
const _hoisted_19 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_20 = { class: "text-gray-800 min-w-200px text-hover-primary" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { class: "text-gray-400" }
const _hoisted_23 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_24 = { class: "text-gray-800" }
const _hoisted_25 = { class: "text-gray-400" }
const _hoisted_26 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_27 = { class: "text-gray-800" }
const _hoisted_28 = { class: "text-gray-400" }
const _hoisted_29 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_30 = { class: "badge-light-primary badge fs-7 px-5 fw-bolder text-gray-400" }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "text-gray-400" }
const _hoisted_33 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_34 = { class: "badge-light-danger badge fs-7 px-5 fw-bolder text-gray-400" }
const _hoisted_35 = { class: "text-gray-400" }
const _hoisted_36 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_37 = { class: "text-gray-800" }
const _hoisted_38 = { class: "text-gray-400" }
const _hoisted_39 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_40 = { class: "text-gray-800" }
const _hoisted_41 = {
  key: 0,
  class: "badge-light-primary bg-light-primary badge fs-7 p-2 fw-bolder"
}
const _hoisted_42 = { class: "text-gray-400" }
const _hoisted_43 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_44 = {
  key: 0,
  class: "badge-light-danger bg-light-danger badge fs-7 p-2"
}
const _hoisted_45 = {
  key: 1,
  class: "badge-light-success bg-light-success badge fs-7 p-2"
}
const _hoisted_46 = {
  key: 2,
  class: "badge-light-info bg-light-info badge fs-7 p-2"
}
const _hoisted_47 = { key: 2 }
const _hoisted_48 = { class: "text-gray-400" }
const _hoisted_49 = { class: "svg-icon svg-icon-1 svg-icon-gray" }
const _hoisted_50 = ["innerHTML"]
const _hoisted_51 = { class: "d-flex flex-center mb-5 fv-row" }
const _hoisted_52 = { style: {"margin-left":"50px"} }
const _hoisted_53 = {
  class: "modal fade show",
  tabindex: "-1",
  id: "kt_modal_review",
  "aria-modal": "true",
  role: "dialog",
  ref: "newTargetModalRef"
}
const _hoisted_54 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_55 = { class: "modal-content" }
const _hoisted_56 = { class: "modal-header" }
const _hoisted_57 = { class: "fw-bolder" }
const _hoisted_58 = {
  class: "btn btn-icon btn-sm btn-active-icon-primary",
  id: "kt_modal_review_event_close",
  "data-bs-dismiss": "modal"
}
const _hoisted_59 = { class: "svg-icon svg-icon-1" }
const _hoisted_60 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_61 = { class: "row mb-7" }
const _hoisted_62 = { class: "col-lg-3 fw-bold fs-6 text-muted mt-3" }
const _hoisted_63 = { class: "col-lg-9" }
const _hoisted_64 = { class: "fw-bolder fs-6 text-dark" }
const _hoisted_65 = {
  key: 0,
  class: "row mb-7"
}
const _hoisted_66 = { class: "col-lg-3 fw-bold fs-6 text-muted mt-3" }
const _hoisted_67 = { class: "col-lg-9" }
const _hoisted_68 = { class: "fw-bolder fs-4 text-dark" }
const _hoisted_69 = { class: "modal-footer" }
const _hoisted_70 = { class: "indicator-label" }
const _hoisted_71 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_vue_editor = _resolveComponent("vue-editor")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t("course.infoExercise")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.exercise.status == 'rejected')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t("course.rejectedFilter")), 1))
            : (_ctx.exercise.status == 'validated')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.moment(_ctx.exercise.reviewDate).format("DD/MM/YYYY, H:mm")), 1))
              : (_openBlock(), _createElementBlock("button", _hoisted_8, _toDisplayString(_ctx.t("course.validate")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("table", _hoisted_13, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_14, [
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classRooms, (item, index) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: index,
                          class: "badge-light-success bg-light-success badge fs-7 p-2 mx-1fw-bolder mb-8"
                        }, _toDisplayString(item.name), 1))
                      }), 128))
                    ])
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen007.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("notes.trimester")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.exercise.trimester == "0"
                      ? _ctx.t("course.allTrimesters")
                      : `${_ctx.t("notes.trimester")} ${_ctx.exercise.trimester}`), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen003.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.subject")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.exercise.subject), 1)
                ]),
                (_ctx.exercise.module)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                      _createElementVNode("td", _hoisted_22, [
                        _createElementVNode("span", _hoisted_23, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen017.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("course.module")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.exercise.module), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_25, [
                    _createElementVNode("span", _hoisted_26, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.question")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.exercise.title), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_28, [
                    _createElementVNode("span", _hoisted_29, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen014.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.publicationDate")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.moment(_ctx.exercise.createdAt).format("DD/MM/YYYY, H:mm")), 1)
                ]),
                (_ctx.exercise.deadline)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                      _createElementVNode("td", _hoisted_32, [
                        _createElementVNode("span", _hoisted_33, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen012.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("course.deadline")) + " : ", 1)
                      ]),
                      _createElementVNode("td", _hoisted_34, _toDisplayString(_ctx.moment(_ctx.exercise.deadline).format("DD/MM/YYYY, H:mm")), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_35, [
                    _createElementVNode("span", _hoisted_36, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen037.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.marked")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_37, _toDisplayString(_ctx.exercise.marked ? _ctx.t("course.yes") : _ctx.t("course.no")), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_38, [
                    _createElementVNode("span", _hoisted_39, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com006.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.specificFor")) + " : ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_40, [
                    (_ctx.studentList.length == 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(_ctx.t("course.allStudent")), 1))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.studentList, (student) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: student._id,
                            class: "badge-light bg-light-info badge fs-7 p-2 mx-1 mb-2 fw-bolder"
                          }, _toDisplayString(student.firstName) + " " + _toDisplayString(student.lastName), 1))
                        }), 128))
                  ])
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_42, [
                    _createElementVNode("span", _hoisted_43, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen037.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("course.review")) + " : ", 1)
                  ]),
                  _createElementVNode("td", null, [
                    (_ctx.exercise.status == 'rejected')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(_ctx.t("course.rejectedFilter")) + " - " + _toDisplayString(_ctx.moment(_ctx.exercise.reviewDate).format("DD/MM/YYYY, H:mm")), 1))
                      : (_ctx.exercise.status == 'validated')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.t("course.validatedFilter")) + " -" + _toDisplayString(_ctx.moment(_ctx.exercise.reviewDate).format("DD/MM/YYYY, H:mm")), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(_ctx.t("course.notValidatedFilter")), 1))
                  ])
                ]),
                (_ctx.exercise.reviewNote)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_47, [
                      _createElementVNode("td", _hoisted_48, [
                        _createElementVNode("span", _hoisted_49, [
                          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.t("course.reason")) + " : ", 1)
                      ]),
                      _createElementVNode("td", {
                        innerHTML: _ctx.exercise.reviewNote
                      }, null, 8, _hoisted_50)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.t("course.quiz")), 1)
          ]),
          _createElementVNode("div", _hoisted_52, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercise.questions, (question, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "py-5",
                key: index
              }, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.t("course.question")) + " " + _toDisplayString(index + 1) + " : " + _toDisplayString(question.text), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answers, (answer, aindex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "padding-left: 40px",
                    key: aindex
                  }, [
                    _createElementVNode("h6", null, [
                      _createTextVNode(_toDisplayString(_ctx.t("course.answer")) + " " + _toDisplayString(aindex + 1) + ": ", 1),
                      _createElementVNode("span", {
                        class: _normalizeClass(`badge-light-${
                    answer.isCorrect ? 'success' : 'danger'
                  } bg-light-${
                    answer.isCorrect ? 'success' : 'danger'
                  } badge fs-7 p-2 fw-bolder m-1`)
                      }, _toDisplayString(answer.text), 3)
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_53, [
      _createElementVNode("div", _hoisted_54, [
        _createElementVNode("div", _hoisted_55, [
          _createVNode(_component_el_form, {
            classs: "form fv-plugins-bootstrap5 fv-plugins-framework",
            "label-position": "top",
            "label-width": "200px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("h2", _hoisted_57, _toDisplayString(_ctx.t("course.exerciseReview")), 1),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("span", _hoisted_59, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_60, [
                _createElementVNode("div", _hoisted_61, [
                  _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.t("course.review")) + " :", 1),
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("span", _hoisted_64, [
                      _createVNode(_component_el_form_item, { prop: "spec" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: _ctx.status,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status) = $event)),
                            as: "select"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_option, {
                                label: _ctx.t('course.notValidatedFilter'),
                                value: "notValidated"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("course.notValidatedFilter")), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_el_option, {
                                label: _ctx.t('course.validatedFilter'),
                                value: "validated"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("course.validatedFilter")), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_el_option, {
                                label: _ctx.t('course.rejectedFilter'),
                                value: "rejected"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("course.rejectedFilter")), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                (_ctx.status == 'rejected')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                      _createElementVNode("label", _hoisted_66, _toDisplayString(_ctx.t("course.reason")) + " :", 1),
                      _createElementVNode("div", _hoisted_67, [
                        _createElementVNode("span", _hoisted_68, [
                          _createVNode(_component_vue_editor, {
                            modelValue: _ctx.reason,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.reason) = $event))
                          }, null, 8, ["modelValue"])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_69, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveReview())),
                  "data-bs-dismiss": "modal",
                  class: "btn btn-light-success mx-5"
                }, [
                  _createElementVNode("span", _hoisted_70, _toDisplayString(_ctx.t("course.review")), 1)
                ]),
                _createElementVNode("button", _hoisted_71, _toDisplayString(_ctx.t("student.close")), 1)
              ])
            ]),
            _: 1
          })
        ])
      ])
    ], 512)
  ], 64))
}